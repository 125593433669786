<!-- eslint-disable -->
<template>
  <component :is="slugId"></component>
</template>

<script>
import PrivacyPolicyContent from 'src/privacy-policy.vue'
const availableSlug = ['privacy-policy']
export default {
  name: 'FooterLinks',
  components: {
    'privacy-policy': PrivacyPolicyContent,
  },
  props: {
    slugId: {
      type: String,
      enum: availableSlug,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (availableSlug.includes(to.params.slugId)) next()
    else
      next({
        name: 'Error404',
      })
  },
}
</script>
<style lang="scss">
.cms-page {
  background: rgba(0, 0, 0, 0.03);
  .page-title {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
      flex-direction: row;
      padding: 20px 40px;
    }
    @media (max-width: 1023px) {
      .page-title--left {
        padding: 0 20px;
      }
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 15px;
      line-height: normal;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      color: $primary;
      color: var(--q-color-primary) !important;
      strong {
        font-weight: 600;
      }
      @media (min-width: 1440px) {
        font-size: 18px;
      }
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
  }
  .page-content {
    padding: 10px 15px;
    @media (min-width: 1024px) {
      padding: 20px 40px;
    }
    @media (max-width: 1023px) {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .container {
    padding: 30px 15px;
    @media (max-width: 1023px) {
      padding: 15px;
    }
  }
  .card-content {
    white-space: normal;
    background: #fff;
    padding: 24px;
    @include border-radius(10px);
    -webkit-box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07);
    @media (max-width: 1023px) {
      padding: 16px 24px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 1rem 0;
      padding: 0;
      line-height: normal;
    }
    h1 {
      font-size: 1.6rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.4rem;
    }
    h3 {
      font-size: 1.3rem;
    }
    h4 {
      font-size: 1.2rem;
    }
    h5 {
      font-size: 1.1rem;
    }
    h6 {
      font-size: 1rem;
    }
    p {
      font-size: 1rem;
    }
  }
}
</style>
